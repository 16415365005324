<template>
    <div class="image-info">
        <div class="image-left" v-if="showContent">
          <inputbox v-if="edit_show" :data="data"></inputbox>
            <div class="Dynamic">
                <div class="head">
                    <div class="user">
                        <img :src="data.user.headIco" class="userIco">
                        <div class="major">
                            <div class="userName">
                                <div>{{data.user.nickName}}</div>
                                <img src="../../assets/imgs/circle/v.png" style="width: 15px;height: 15px;margin-left: 5px;">
                            </div>
                          <div class="userMajor"><span v-if="data.user.address.cityName">{{data.user.address.cityName[1]}}</span><span v-if="data.user.major && data.user.address.cityName"> | </span>{{data.user.major}}</div>
                            <div class="userMajor">{{data.createTime}}</div>
                        </div>
                    </div>

                  <div class="action">
                    <el-dropdown @command="more_action" v-if="me.uuid == data.user.uuid">
					<span class="el-dropdown-link">
						<i class="el-icon-caret-bottom" style="font-size: 24px"></i>
					</span>
                      <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item v-if="me.uuid == data.user.uuid" command="edit">编辑内容</el-dropdown-item>
                          <el-dropdown-item v-if="me.uuid == data.user.uuid"  command="delete" >删除该条内容</el-dropdown-item>

                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
                <!-- 内容 -->
                <div class="substance">
                    <span class="contentType">
                        <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                    </span>
	                <span class="t" style="color: #1C7EFF; margin-right: 4px" v-for="(item, index) in data.aT" :key="index">@{{item.nickName}}</span>
	                <span v-if="data.topicId!=0" style="color:#2970ff;" @click="openTopic(data.topic)">#{{data.topic}}#</span>
                    <span class="t" style="white-space: pre-wrap">{{data.content}}</span>
                </div>

                <!-- 视频 -->
                <div style="margin-top: 10px" v-if="data.videos">
                    <video-player  class="video-player vjs-custom-skin" id="videoPlayer" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
                </div>
	            <div style="border-bottom: 1px solid #999999;margin-top: 10px"></div>
	            <DynamicOperation v-if="data.comment" :data="data"></DynamicOperation>
            </div>

        </div >
      <div class="image-left" v-else>
        <div class="Dynamic" style="min-height: 80px">
          内容已被作者删除
        </div>
      </div>

<!--        <div class="image-right">-->
<!--            <el-tabs  v-model="HotactiveName">-->
<!--                <el-tab-pane label="阅读排行榜" name="first">-->
<!--                    <div style="padding-bottom: 20px;cursor: pointer;" @click="content_info(item.id)" v-for="(item,index) in content_list" :key="index">-->
<!--                        <div style="float: left; clear: both;margin-right: 5px;height: 75px;margin-top: 5px;overflow: hidden;" align="center">-->
<!--                            <img :src="item.images[0]" height="75" width="120" alt="" hspace="8" ><br />-->
<!--                        </div>-->
<!--                        <p style="font-size: 16px;padding-left: 2px;height: 45px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1;overflow: hidden;"><span class="ranking" :class="{'active':index > 2}">{{index+1}}、</span> {{item.title}}</p>-->
<!--                        <p  style="font-size: small;letter-spacing:1px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;" v-html="item.content"></p>-->
<!--                    </div>-->
<!--                </el-tab-pane>-->

<!--                <el-tab-pane label="评论排行榜" name="second">-->
<!--                    <div style="height: 30px;font-size: 10px;cursor: pointer;" @click="content_info(item.id)" v-for="(item,index) in content_list" :key="index">-->
<!--                        <div class="comment" :class="{'active':index > 2}">{{index+1}}</div>-->
<!--                        <div style="width: 200px;font-size:14px;float: left;margin-top: 2px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;">{{item.title}}</div>-->
<!--                        <div style="width: 30px;font-size:14px;float: left;margin-top: 2px;margin-left: 2px;color: #49A3D7;text-align:center;">{{item.comment_sum}}</div>-->
<!--                    </div>-->
<!--                </el-tab-pane>-->
<!--            </el-tabs>-->
<!--        </div>-->
    </div>
</template>

<script>
    import DynamicOperation from "@/pages/circle/components/DynamicOperation.vue";
    import Inputbox from "@/pages/circle/components/InputBox.vue";

    export default {
        name: "videoInfo",
        components: {Inputbox, DynamicOperation},
        computed: {
            me() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },
        data () {
            return {
                playerOptions: {
                    playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                    autoplay: false, // 如果为true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 是否视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4", // 类型
                        src: '' // url地址
                    }],
                    poster: '', // 封面地址
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true, // 当前时间和持续时间的分隔符
                        durationDisplay: true, // 显示持续时间
                        remainingTimeDisplay: false, // 是否显示剩余时间功能
                        fullscreenToggle: true // 是否显示全屏按钮
                    }
                },

                data: {
                  user: {
                    address: {}
                  }
                },
                HotactiveName: 'first',
                content_list: [],
                edit_show: false,
                showContent: true
            }
        },

        watch: {
            '$route'() {
                this.getInspirationList();
            }
        },

        methods: {
	        //打开话题列表
	        openTopic(name){
		        this.utils.b('/topic/info/'+name)
	        },
            //下拉菜单触发的方法
            more_action:function(e){
                if (this.is_login == 0) {
                    this.err("请先登录");
                    return;
                }
                var that = this;
                if (e == "delete") {
                    that.$confirm('删除后无法恢复，确认删除吗？')
                        .then(_ => {
                            console.log(_);
                            this.newApi.InspirationDelete({id:that.data.id}).then(ret =>{
                                that.utils.sus(ret.data);
                                that.show = false;
                            })
                        })
                        .catch(_ => {
                            console.log(_);
                        });
                }

                if (e == "edit") {
                  if (that.data.bgbType !== 2) {
                    that.edit_show = true;
                  } else {
                    that.utils.a('content/add/' + that.data.id)
                  }
                }
            },

            getInspirationList(){
                var that = this;
                // 视频
                this.newApi.getInspirationInfo({id: this.$route.params.id}).then((res) => {
                    that.data = res.data;
                    if (res.data.videos) {
                        that.playerOptions.sources[0].src = res.data.videos[0].url;
                        that.playerOptions.poster = res.data.videos[0].url + '?x-oss-process=video/snapshot,t_0,f_jpg';
                    }
                }).catch((err => {
                  that.showContent = false
                }))
            },

            content_info(id){
                window.open('/content/info/'+id);
            },
        },

      created() {
        this.getInspirationList();
      },

      mounted() {

        }
    }
</script>

<style scoped>
    .image-info {
        margin: 0 auto;
        width: 1500px;
        display: flex;
      min-height: 100vh;
    }

    .image-left {
        width: 74%;
        margin-right: 10px;
    }

    .image-right {
        width: 25%;
        padding: 10px 16px;
        margin-bottom: 10px;
        background-color: #ffffff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
    }

    .Dynamic{
        padding: 24px 38px 10px;
        margin-bottom: 10px;
        background-color: #ffffff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
    }
    .Dynamic .head{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .Dynamic .head .user{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .Dynamic .head .user .userIco{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 12px;
    }
    .Dynamic .head .user .major .userName{
        font-weight: 600;
        color: #444444;
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        align-items:flex-end;
    }
    .Dynamic .head .user .major .userMajor{
        font-size: 13px;
        color: #999999;
        margin-top: 5px;

    }
    .Dynamic .substance{
        margin-top: 20px;
        line-height: 26px;
        font-size: 15px;
    }
    .Dynamic .substance .contentType{
        color: #031F88;
        height: 16px;
        font-weight: 600;
        margin-right: 10px;
    }
    .Dynamic .substance .t{
        color: #555555;
    }
    .Dynamic .imgs{
        margin-top: 14px;
    }
    .spacebetween{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .Dynamic .volume{
        height: 35px;
        line-height: 35px;
        color: #999999;
        font-size: 14px;
    }
    .Dynamic .bottom{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height:50px;
        height: 50px;
        color: #444444;
        font-size: 16px;
        text-align: center;
    }
    .Dynamic .bottom .btn{
        display: flex;
        justify-content:center;
        align-items: center;
        flex: 1;
    }
    .mr5{
        margin-right: 5px;
    }


</style>
