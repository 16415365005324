import { render, staticRenderFns } from "./videoInfo.vue?vue&type=template&id=4231fd58&scoped=true&"
import script from "./videoInfo.vue?vue&type=script&lang=js&"
export * from "./videoInfo.vue?vue&type=script&lang=js&"
import style0 from "./videoInfo.vue?vue&type=style&index=0&id=4231fd58&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4231fd58",
  null
  
)

export default component.exports